import { Platform } from "react-native";

import { NotificationPayload, PushNotification } from "../types";

export default function getNotificationPayload(
  notification: PushNotification,
): NotificationPayload | undefined {
  if (!notification?.payload?.payload) {
    return undefined;
  }

  return Platform.OS === "android"
    ? // android notification payload is string,
      // so we have to parse it into json
      JSON.parse(notification.payload?.payload as string)
    : notification.payload?.payload;
}
