import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
} from "@reduxjs/toolkit";

import { getActionDispatcherWithPayload } from "@marta/utils";

import { WizardState } from "./wizard-types";

export const setWizardCache =
  createAction<WizardState["cache"]>("setWizardCache");
export const useSetWizardCache = getActionDispatcherWithPayload(setWizardCache);

export const initialAppState: WizardState = {};

export const createSlice = <State>(options: {
  initialState: State;
  reducers?: (builder: ActionReducerMapBuilder<State & WizardState>) => void;
}) => {
  const reducer = createReducer(
    Object.assign({}, initialAppState, options.initialState),
    (builder) => {
      builder.addCase(setWizardCache, (state, action) => {
        state.cache = action.payload;
      });

      if (options?.reducers) options.reducers(builder);
    },
  );

  return {
    reducer,
    getInitialState: reducer.getInitialState,
  };
};
