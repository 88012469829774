import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

import { UserSession } from "@marta/common-utils";

import { AuthState, initialAppState } from "./auth-types";

const selectSlice = <T extends { auth: AuthState }>(state: T) =>
  state.auth || initialAppState;

export const selectAuth = createSelector([selectSlice], (state) => {
  const { loggedIn: _, ...user } = state;
  // if (!user.userId) {
  //   throw new Error("User needs to be logged in to call useSession hook!");
  // }

  return user as UserSession;
});

export const selectExpiredSession = createSelector(
  [selectSlice],
  (state) => state.expiredSession,
);

export const selectLoggedIn = createSelector(
  [selectSlice],
  (state) => state.loggedIn,
);

export const selectTokens = createSelector([selectSlice], (state) => ({
  accessToken: state.accessToken,
  refreshToken: state.refreshToken,
}));

/**
 *
 * @returns Session information of the user. Only to be used when user is logged in.
 * @deprecated Use this inside packages folder, `useCgSession` or `useFamSession` inside apps folder instead.
 */
export const useSession = () => useSelector(selectAuth);

/**
 *
 * @returns Logged-in status boolean.
 */
export const useLoggedIn = () => useSelector(selectLoggedIn);

export const useTokens = () => useSelector(selectTokens);

export const useExpiredSession = () => useSelector(selectExpiredSession);
