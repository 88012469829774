import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
} from "@reduxjs/toolkit";

import { AppLocale } from "@marta/common-utils";
import { getActionDispatcherWithPayload } from "@marta/utils";

import { AppState } from "./app-types";

export const toggleLocale = createAction<AppLocale>("toggleLocale");
export const useSetLocale = getActionDispatcherWithPayload(toggleLocale);

const toggleTheme = createAction<undefined>("toggleTheme");
const completeOnboarding = createAction<undefined>("completeOnboarding");

// --- User device id --- //
type UserDeviceIdType = AppState["userDeviceId"];

export const setUserDeviceId =
  createAction<UserDeviceIdType>("setUserDeviceId");

export const useSetUserDeviceId =
  getActionDispatcherWithPayload(setUserDeviceId);
// ---------------------- //
//
//
//
// --- Notification modal state --- //
type NotificationModalStateType = AppState["modalState"]["notification"];

export const setNotificationModalState =
  createAction<NotificationModalStateType>("setNotificationModalState");

export const useSetNotificationModalState = () => {
  const dispatch = useDispatch();
  return useCallback(
    (payload: NotificationModalStateType, immediate = false) => {
      if (immediate) {
        dispatch(setNotificationModalState(payload));
      } else {
        setTimeout(() => dispatch(setNotificationModalState(payload)), 2000);
      }
    },
    [dispatch],
  );
};
// -------------------------------- //

// --- Locale cache --- //
type LocaleCacheType = AppState["localeCache"];
export const setLocaleCache = createAction<LocaleCacheType>("setLocaleCache");
export const useSetLocaleCache = getActionDispatcherWithPayload(setLocaleCache);
// ------------------- //

// --- Smart App Banner --- //
type SmartAppBannerClosedType = AppState["smartAppBannerClosed"];
export const setSmartAppBannerToClosed = createAction<SmartAppBannerClosedType>(
  "setSmartAppBannerToClosed",
);
export const useSetSmartAppBannerToClosed = getActionDispatcherWithPayload(
  setSmartAppBannerToClosed,
);
// ------------------- //

// --- User cache --- //
const setUserCache = createAction<AppState["user"]>("setUserCache");
export const useSetUserCache = getActionDispatcherWithPayload(setUserCache);
// ------------------- //

export const initialAppState: AppState = {
  locale: AppLocale.En,
  colorMode: "light",
  userDeviceId: null,
  localeCache: {},
  modalState: {
    notification: "initial",
  },
  smartAppBannerClosed: false,
  user: undefined,
};

export const createSlice = <State>(options: {
  initialState: State;
  reducers?: (builder: ActionReducerMapBuilder<State & AppState>) => void;
}) => {
  const reducer = createReducer(
    Object.assign({}, initialAppState, options.initialState),
    (builder) => {
      builder
        .addCase(toggleLocale, (state, action) => {
          state.locale = action.payload;
        })
        .addCase(toggleTheme, (state) => {
          state.colorMode = state.colorMode === "dark" ? "light" : "dark";
        })
        .addCase(setNotificationModalState, (state, action) => {
          if (!state.modalState) {
            // since this is a new state, we need to initialize it
            state.modalState = {
              notification: "initial",
            };
          } else if (
            state.modalState.notification === "granted" &&
            action.payload === "requested"
          ) {
            // if the user has already granted permission, we don't want to show the modal again
            return;
          }

          state.modalState.notification = action.payload;
        })
        .addCase(setUserDeviceId, (state, action) => {
          state.userDeviceId = action.payload;
        })
        .addCase(setSmartAppBannerToClosed, (state, action) => {
          state.smartAppBannerClosed = action.payload;
        })
        .addCase(setLocaleCache, (state, action) => {
          if (!state.localeCache) {
            // since this is a new state, we need to initialize it
            state.localeCache = {};
          }

          const [localeKey] = Object.keys(action.payload);
          state.localeCache[localeKey] = action.payload[localeKey];
        })
        .addCase(setUserCache, (state, action) => {
          state.user = action.payload;
        });

      if (options?.reducers) options.reducers(builder);
    },
  );

  return {
    reducer,
    getInitialState: reducer.getInitialState,
    actions: {
      toggleLocale,
      toggleTheme,
      completeOnboarding,
      setSmartAppBannerToClosed,
      setNotificationModalState,
      setLocaleCache,
    },
  };
};
