import { Platform } from "react-native";

import {
  Api,
  NetworkError,
  RequestHeaders,
  RequestOptions,
  ResponseInterceptor,
} from "@marta/utils/network";

import envConfig from "./env";

const { api } = envConfig;

// select headers based on platform
const headers = Platform.select({
  web: {
    "Content-Type": "application/json",
    "Cache-Control": "must-revalidate,no-cache,no-store",
    "Access-Control-Allow-Headers":
      "with-new-session,access-token,refresh-token",
  },
  default: {
    "Accept-Language": "tr-TR,tr;q=0.8,en-US;q=0.5,en;q=0.3",
    "Accept-Encoding": "gzip, deflate, br",
    Referer: api.origin,
    Origin: api.origin,
    Connection: "keep-alive",
    "Content-Type": "application/json",
    "Cache-Control": "must-revalidate,no-cache,no-store",
    "Access-Control-Allow-Headers":
      "with-new-session,access-token,refresh-token",
  },
});

export type {
  NetworkError,
  RequestHeaders,
  RequestOptions,
  ResponseInterceptor,
};

export default new Api(api.url, headers);
