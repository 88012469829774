import { createSlice } from "@marta/core/features/auth/slice/auth-reducer";

import { AuthState } from "./auth-types";

/**
 * You can extends this slice
 * providing new options as parameter.
 *
 * example:
 *
 * First you need to extend the `AuthState` in './auth-types.ts'.
 *
 * import { createAction } from "@reduxjs/toolkit";
 * const incrementBy = createAction<number>("incrementBy");
 *
 * const slice = createSlice<AuthState>({
 *   initialState: {
 *     count: 0
 *   } as AuthState,
 *   reducers: (builder) => {
 *     builder.addCase(incrementBy, (state, action) => {
 *       state.count += action.payload;
 *     })
 *   }
 * });
 */

const { reducer, actions } = createSlice<AuthState>({
  initialState: {} as AuthState, // downcast to `AuthState` to not re-define `BaseState` properties.
});

export const authReducer = reducer;
/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { incrementBy } = useAuthSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(incrementBy(3));
 *   };
 * }
 */
export const useAuthActions = () => {
  // authend any `createAction` result here
  return {
    ...actions,
    //incrementBy
  };
};
