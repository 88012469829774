import { Platform } from "react-native";

export default Platform.select({
  // TODO: ios setup
  ios: "",
  android:
    "977085460082-9aiofbggrf5n6agsmf1oepvqk3i26gak.apps.googleusercontent.com",
  default:
    "977085460082-3fsqprg6h73691lfn560iscaa6pka6tb.apps.googleusercontent.com",
});
