import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Action, createSlice } from "@reduxjs/toolkit";
import { isEqual } from "lodash";

import { getActionDispatcherWithPayload } from "@marta/utils";

import { useCgSession } from "@/auth/slice";

import { JobState } from "./job-types";

export const initialState = {
  filtersByUser: {},
} as JobState;

const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    updateFiltersByUser(
      state,
      action: Action<string> & {
        payload: {
          userId: string;
          filters: Record<string, unknown>[];
        };
      },
    ) {
      const { userId, filters } = action.payload;
      if (!state.filtersByUser[userId]) {
        state.filtersByUser[userId] = [];
      }
      state.filtersByUser[userId] = filters;
    },
    overrideFilters(
      state,
      action: Action<string> & {
        payload: {
          newFilters: Record<string, unknown>[];
          userId: string;
        };
      },
    ) {
      const { userId, newFilters } = action.payload;
      // get the current filter options
      const currentFilters = state.filtersByUser[userId];

      // override the current filter options with the new filter options
      if (currentFilters) {
        const overriddenFilters = currentFilters.map((filter) => {
          const newFilter = newFilters.find(
            (_newFilter) => _newFilter.field === filter.field,
          );

          if (newFilter) {
            return {
              ...newFilter,
              conditionType: filter.conditionType,
            };
          }

          return filter;
        });

        if (!isEqual(overriddenFilters, currentFilters)) {
          state.filtersByUser[userId] = [...overriddenFilters];
        } else {
          __DEV__ &&
            console.log("Everything is the same, we won't update filters");
        }
      }
    },

    resetFilters(
      state,
      action: Action<string> & {
        payload: {
          userId: string;
        };
      },
    ) {
      state.filtersByUser[action.payload.userId] = [];
    },
  },
});

export const useResetFilters = () => {
  const { userId } = useCgSession();
  const dispatch = useDispatch();

  return useCallback(
    () =>
      dispatch(
        jobSlice.actions.resetFilters({
          userId: userId as string,
        }),
      ),
    [dispatch, userId],
  );
};

export const useUpdateFilters = () => {
  const { userId } = useCgSession();
  const dispatch = useDispatch();

  return useCallback(
    (filters: Record<string, unknown>[]) =>
      dispatch(
        jobSlice.actions.updateFiltersByUser({
          userId: userId as string,
          filters,
        }),
      ),
    [dispatch, userId],
  );
};

export const useOverrideFilters = () => {
  const { userId } = useCgSession();
  const dispatch = useDispatch();

  return useCallback(
    (newFilters: Record<string, unknown>[]) =>
      dispatch(
        jobSlice.actions.overrideFilters({
          userId: userId as string,
          newFilters,
        }),
      ),
    [dispatch, userId],
  );
};

export const useUpdateFiltersByUser = getActionDispatcherWithPayload(
  jobSlice.actions.updateFiltersByUser,
);

export const jobReducer = jobSlice.reducer;
/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { incrementBy } = useAppSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(incrementBy(3));
 *   };
 * }
 */
export const useJobActions = () => {
  return jobSlice.actions;
};
