import { isPossiblePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { addMethod, setLocale, string } from "yup";

const YUP_PHONE_METHOD = "phone";
const romanianPhoneRegex =
  // eslint-disable-next-line max-len
  /^(?:(?:(?:00\s?|\+)40\s?|0)(?:7\d{2}\s?\d{3}\s?\d{3}|(21|31)\d{1}\s?\d{3}\s?\d{3}|((2|3)[3-7]\d{1})\s?\d{3}\s?\d{3}|(8|9)0\d{1}\s?\d{3}\s?\d{3}))$/;

// Add a custom yup method to validate phone numbers
addMethod(
  string,
  YUP_PHONE_METHOD,
  function yupPhoneLite(errorMessage?: string) {
    const errMsg =
      typeof errorMessage === "string" && errorMessage
        ? errorMessage
        : "validation.invalid_tel";

    return this.test(YUP_PHONE_METHOD, errMsg, (value?: string) => {
      try {
        if (value === undefined || value === null || value === "") {
          return true;
        }
        if (value.substring(0, 3) === "+40") {
          return romanianPhoneRegex.test(value);
        }
        return isPossiblePhoneNumber(value) && isValidPhoneNumber(value);
      } catch {
        return false;
      }
    });
  },
);

// Set the yup locale to use our custom messages
setLocale({
  // use constant translation keys for messages without values
  mixed: {
    required: "validation.required_field",
    notType: "validation.invalid_number",
  },
  // use functions to generate an error object that includes the value from the schema
  string: {
    default: "validation.required_field",
    email: "validation.invalid_email",
    min: ({ min }) => ({
      key: "validation.min_length",
      values: { minLength: min },
    }),
    max: ({ max }) => ({
      key: "validation.max_length",
      values: { maxLength: max },
    }),
  },
  number: {
    default: "validation.required_field",
    min: ({ min }) => ({ key: "validation.min", values: { min } }),
    max: ({ max }) => ({ key: "validation.max", values: { max } }),
    positive: "validation.positive",
    test: "validation.invalid_number",
  },
  date: {
    default: "validation.required_field",
    min: ({ min }) => ({ key: "validation.min_date", values: { min } }),
    max: ({ max }) => ({ key: "validation.max_date", values: { max } }),
  },
  array: {
    default: "placeholder.select",
    min: "placeholder.multiple_choice",
  },
});
