// import { isValid } from "date-fns";
//
// this class is a wrapper around the fetch api
// it provides a simple interface for fetching data from the backend
// and also handles authentication
//
// the api needs to be constructed with the base url of the backend
// and then the token can be set using the setToken method
//
export default class Api {
  // the base url of the backend
  private baseUrl: string;
  // the token to be used for authentication
  private accessToken: string | null;
  private refreshToken: string | null;
  // the headers to be used for all requests
  private headers: RequestHeaders;
  // the response interceptor to be used for all requests
  private responseInterceptor: ResponseInterceptor | null = null;

  constructor(baseUrl: string, headers: RequestHeaders = {}) {
    this.baseUrl = baseUrl;
    this.accessToken = null;
    this.refreshToken = null;
    this.headers = {
      "Content-Type": "application/json",
      "Cache-Control": "must-revalidate,no-cache,no-store",
      "Access-Control-Allow-Headers":
        "with-new-session,access-token,refresh-token",
      ...headers,
    };
  }

  // this is used to transform the response from the backend
  // to a format that is easier to work with
  // for example, dates are converted to Date objects
  // and arrays are converted to arrays of objects
  private transformResult = (data: any): any => {
    if (data === null) {
      return null;
    }

    if (data instanceof Array) {
      return data.map(this.transformResult);
    }

    if (typeof data === "object") {
      return Object.entries(data).reduce((result, [key, value]) => {
        return {
          ...result,
          [key]: this.transformResult(value),
        };
      }, {});
    }

    // try {
    //   if (typeof data === "string" && isValid(new Date(data))) {
    //     return new Date(data);
    //   }
    // } catch (e) {}

    return data;
  };

  // set the response interceptor
  public setResponseInterceptor(interceptor: ResponseInterceptor | null) {
    this.responseInterceptor = interceptor;
  }

  // set the token to be used for authentication
  public setToken(
    tokens: {
      accessToken: string | null;
      refreshToken: string | null;
    } = { accessToken: null, refreshToken: null },
  ) {
    this.accessToken = tokens.accessToken;
    this.refreshToken = tokens.refreshToken;
  }

  // get the token
  private getTokens() {
    return {
      accessToken: this.accessToken,
      refreshToken: this.refreshToken,
    };
  }

  // fetch the data
  async fetch(url: string, method = Method.GET, options: RequestOptions = {}) {
    const {
      body,
      baseUrl,
      headers = {},
      shouldTransformResult = true,
    } = options;

    let finalUrl = url;
    let finalBody;

    const _baseUrl = baseUrl || this.baseUrl;

    // if url is relative, prepend the base url
    if (!url.startsWith("http")) {
      finalUrl = `${_baseUrl}${url.startsWith("/") ? "" : "/"}${url}`;
    }

    if (method === Method.GET && body) {
      if (body instanceof FormData) {
        throw new Error("Cannot use FormData with GET method");
      }

      // if the method is GET, add the body as query parameters
      const params = new URLSearchParams(body);
      finalUrl = `${finalUrl}?${params.toString()}`;
    } else if (body) {
      //
      // method is not GET, but the body exists, construct the final body
      finalBody =
        typeof body === "object" && !(body instanceof FormData)
          ? JSON.stringify(body)
          : body;
    }

    const { refreshToken, accessToken } = this.getTokens();
    let response = await fetch(finalUrl, {
      method,
      mode: "cors",
      headers: {
        ...this.headers,
        ...(accessToken && refreshToken
          ? {
              Authorization: `Bearer ${accessToken}`,
              "Refresh-Token": refreshToken,
            }
          : {}),
        ...headers,
      },
      // if the method is not GET, add the body
      body: method !== Method.GET ? finalBody : undefined,
    });

    // if the response interceptor is set, call it
    if (this.responseInterceptor) {
      response = this.responseInterceptor(response);
    }

    if (!response.ok) {
      try {
        return Promise.reject({
          ...(await response.json()),
          status: response.status,
        });
      } catch (e) {
        return Promise.reject(response);
      }
    }

    try {
      const result = await response.json();
      return shouldTransformResult ? this.transformResult(result) : result;
    } catch (e) {
      __DEV__ && console.error(e);
      return undefined;
    }
  }

  // get the data
  async get<
    TRes,
    TBody extends { [key: string | number | symbol]: any } = {
      [key: string | number | symbol]: any;
    },
  >(url: string, options: RequestOptions<TBody> = {}): Promise<TRes> {
    return this.fetch(url, Method.GET, options);
  }

  // post the data
  async post<
    TRes,
    TBody extends { [key: string | number | symbol]: any } | Array<any> = {
      [key: string | number | symbol]: any;
    },
  >(url: string, options: RequestOptions<TBody> = {}): Promise<TRes> {
    return this.fetch(url, Method.POST, options);
  }

  // put the data
  async put<
    TRes,
    TBody extends
      | { [key: string | number | symbol]: any }
      | Array<any>
      | Blob = Record<string, unknown>,
  >(url: string, options: RequestOptions<TBody> = {}): Promise<TRes> {
    return this.fetch(url, Method.PUT, options);
  }

  // patch the data
  async patch<
    TRes,
    TBody extends
      | { [key: string | number | symbol]: any }
      | Array<any> = Record<string, unknown>,
  >(url: string, options: RequestOptions<TBody> = {}): Promise<TRes> {
    return this.fetch(url, Method.PATCH, options);
  }

  // delete the data
  async delete<
    TRes,
    TBody extends { [key: string]: any } = {
      [key: string | number | symbol]: any;
    },
  >(url: string, options: RequestOptions<TBody> = {}): Promise<TRes> {
    return this.fetch(url, Method.DELETE, options);
  }

  // head the data
  async head<T>(url: string, options: RequestOptions = {}): Promise<T> {
    return this.fetch(url, Method.HEAD, options);
  }

  // options the data
  async options<T>(url: string, options: RequestOptions = {}): Promise<T> {
    return this.fetch(url, Method.OPTONS, options);
  }

  // upload the file
  async upload<T>(
    url: string,
    options: RequestOptions<File | Blob> = {},
  ): Promise<T> {
    if (!options.body) {
      throw new Error("File is required");
    }

    const formData = new FormData();
    formData.append("file", options.body);

    return this.fetch(url, Method.PUT, { ...options, body: formData });
  }
}

// response interceptor type
export type ResponseInterceptor = (response: Response) => Response;

export type RequestHeaders = Record<string, string | undefined | null>;

// request options type
export type RequestOptions<TBody = { [key: string | number | symbol]: any }> = {
  baseUrl?: string;
  body?: TBody;
  headers?: RequestHeaders;
  shouldTransformResult?: boolean;
};

export type NetworkError = {
  status: number;
  message: string;
};

// http methods
export enum Method {
  "GET" = "GET",
  "POST" = "POST",
  "PUT" = "PUT",
  "PATCH" = "PATCH",
  "DELETE" = "DELETE",
  "HEAD" = "HEAD",
  "OPTONS" = "OPTIONS",
}
