export default function isRedirectionPathnameValid(pathname: string) {
  // Exclude "/" and "/{locale}"
  if (pathname === "/" || (pathname.length === 3 && pathname.startsWith("/"))) {
    return false;
  }

  // Exclude sessionless screens
  if (
    pathname.includes("/login") ||
    pathname.includes("/register") ||
    pathname.includes("/confirm-email") ||
    pathname.includes("/reset-password") ||
    pathname.includes("/set-password")
  ) {
    return false;
  }

  return true;
}
