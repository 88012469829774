import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  ActionReducerMapBuilder,
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";

import { Api } from "@marta/utils/network";

import { useApi } from "../../network/provider";
import { RootState } from "../../redux";

import { CdnState } from "./cdn-types";

// --- Signed urls --- //
export const setSignedUrls = createAsyncThunk(
  "setSignedUrls",
  async (payload: { api: Api; url: string; sub?: string }) => {
    const { api, url, sub } = payload;

    const signedUrl = await api.post<string>("/v1/image/sign", {
      body: {
        key: url,
        sub,
      },
    });

    return {
      url,
      signedUrl,
      isLoading: false,
      error: undefined,
    };
  },
  {
    condition: (payload, thunkApi) => {
      const { getState } = thunkApi;
      const { url } = payload;

      const state = (getState() as RootState).cdn.signedUrls;
      const urlState = state[url] || {};

      if (urlState.status === "loading" || urlState.status === "success") {
        return false;
      }
    },
  },
);

// --- Reset signed urls --- //
export const resetSignedUrls = createAction<string>("resetSignedUrls");

export const useSetSignedUrl = () => {
  const api = useApi();
  const dispatch = useDispatch();
  return useCallback(
    (url: string, sub?: string) => {
      // @ts-expect-error correct type but not inferred correctly
      return dispatch(setSignedUrls({ url, sub, api }));
    },
    [dispatch, api],
  );
};

// export const useSetSignedUrls = getActionDispatcherWithPayload(setSignedUrls);
// ------------------- //

export const initialAppState: CdnState = {
  signedUrls: {},
};

export const createSlice = <State>(options: {
  initialState: State;
  reducers?: (builder: ActionReducerMapBuilder<State & CdnState>) => void;
}) => {
  const reducer = createReducer(
    Object.assign({}, initialAppState, options.initialState),
    (builder) => {
      builder
        .addCase(setSignedUrls.pending, (state, action) => {
          if (!state.signedUrls) {
            // since this is a new state, we need to initialize it
            state.signedUrls = {};
          }
          const { url } = action.meta.arg;

          state.signedUrls[url] = {
            url,
            status: "loading",
          };
        })
        .addCase(setSignedUrls.fulfilled, (state, action) => {
          if (!state.signedUrls) {
            // since this is a new state, we need to initialize it
            state.signedUrls = {};
          }

          const { url } = action.meta.arg;
          state.signedUrls[url] = {
            url,
            status: "success",
            signedUrl: action.payload.signedUrl,
          };
        })
        .addCase(setSignedUrls.rejected, (state, action) => {
          if (!state.signedUrls) {
            // since this is a new state, we need to initialize it
            state.signedUrls = {};
          }

          const { url } = action.meta.arg;
          state.signedUrls[url] = {
            url,
            status: "error",
            error: action.error.message || "Unknown error",
          };
        })
        .addCase(resetSignedUrls, (state, action) => {
          if (!state.signedUrls) {
            // since this is a new state, we need to initialize it
            state.signedUrls = {};
          }

          const url = action.payload;
          state.signedUrls[url] = {
            status: "not_init",
          };
        });

      if (options?.reducers) options.reducers(builder);
    },
  );

  return {
    reducer,
    getInitialState: reducer.getInitialState,
  };
};
