import {
  identify as _identify,
  load,
  page as _page,
  ready,
  reset as _reset,
  track,
} from "rudder-sdk-js";

import { RudderClient } from "./types";

export const reset: RudderClient["reset"] = () => {
  return new Promise((resolve) => {
    _reset();
    resolve();
  });
};

export const initialize: RudderClient["initialize"] = async ({
  writeKey,
  dataPlaneUrl,
}) => {
  return new Promise((resolve) => {
    load(writeKey, dataPlaneUrl, { logLevel: "debug" });
    ready(resolve);
  });
};

export const identify: RudderClient["identify"] = (user) => {
  return new Promise((resolve) => {
    _identify(
      user.userId,
      {
        userId: user.userId,
        familyId: user.familyId,
        clientId: user.clientId,
        caregiverId: user.caregiverId,
      },
      resolve,
    );
  });
};

export const page: RudderClient["page"] = (pageName, args) => {
  return new Promise((resolve) => {
    _page(pageName, args, resolve);
  });
};

export { track };

/**
 * @deprecated Use `rudderClient` instead.
 */
export enum AnalyticType {
  "Page" = "page",
  "Track" = "track",
}

/**
 * @deprecated Use `rudderClient` instead.
 */
export const rudderAnalyzer = (
  typeOfAnalytics: AnalyticType,
  action?: string,
  properties?: Record<string, string | number>,
) => {
  if (typeof action !== "undefined") {
    if (typeOfAnalytics === AnalyticType.Page) {
      page(action, properties || {});
    } else {
      track(action, properties);
    }
  }
};
