import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

export default function useStateCallback<T = undefined>(
  initialState: T,
): [T, (state: SetStateAction<T>, cb?: (state: T) => void) => void] {
  const [state, setState] = useState(initialState);
  // init mutable ref container for callbacks
  const cbRef = useRef<((state: T) => void) | undefined>(undefined);

  const setStateCallback = useCallback(
    (newState: React.SetStateAction<T>, cb?: (state: T) => void) => {
      cbRef.current = cb; // store current, passed callback in ref
      setState(newState);
    },
    [],
  ); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `undefined` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = undefined; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}
