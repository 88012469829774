import { SchemaOf } from "yup";

export enum IdentifierType {
  email = "email",
  phone = "phone",
}

export enum LoginMethod {
  otp = "otp",
  password = "password",
  forgotPassword = "forgotPassword",
}

export type IdentifierFormValues = {
  identifierType: keyof typeof IdentifierType;
  email: string | undefined;
  phone: string | undefined;
};

export type LoginFormValues = IdentifierFormValues & {
  loginMethod: keyof typeof LoginMethod;
  password?: string;
};

export type RegisterFormValues = IdentifierFormValues & {
  password: string;
  confirmPassword: string;
  disclaimerChecked: boolean;
  marketingConsent?: boolean;
};

export type IdentifierSchema = SchemaOf<IdentifierFormValues>;
export type LoginSchema = SchemaOf<LoginFormValues>;
export type RegisterSchema = SchemaOf<RegisterFormValues>;
