import appmarta from "./appmarta";
import dev from "./development";
import env from "./getCurrentEnv";
import prod from "./production";
import staging from "./staging";

export { env };
export const isProduction = env === "prod";
export const envConfig = { dev, staging, prod, appmarta }[env];
export default envConfig;
