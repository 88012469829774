import { IdentifierType } from "../form/types";

export default function extractEmailOrPhone<
  T extends WithIdentifierType<{ email?: string; phone?: string }>,
>({ email, phone, identifierType, ...restValues }: T) {
  if (identifierType === IdentifierType.phone && phone != null) {
    return {
      ...restValues,
      phone,
    };
  } else if (identifierType === IdentifierType.email && email != null) {
    return {
      ...restValues,
      email,
    };
  } else {
    throw new Error(
      "Email and phone cannot be undefined during auth operations!",
    );
  }
}

export type WithIdentifierType<T> = T & {
  identifierType: IdentifierType;
};
