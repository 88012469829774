import { createAction } from "@reduxjs/toolkit";

import { createSlice } from "@marta/core/features/app/slice/app-reducer";
import { getActionDispatcherWithPayload } from "@marta/utils";

import { AppState } from "./app-types";
/**
 * You can extends this slice
 * providing new options as parameter.
 *
 * example:
 *
 * First you need to extend the `AppState` in './app-types.ts'.
 *
 * import { createAction } from "@reduxjs/toolkit";
 * const incrementBy = createAction<number>("incrementBy");
 *
 * const slice = createSlice<AppState>({
 *   initialState: {
 *     count: 0
 *   } as AppState,
 *   reducers: (builder) => {
 *     builder.addCase(incrementBy, (state, action) => {
 *       state.count += action.payload;
 *     })
 *   }
 * });
 */

/**
 * ------------------------------
 * Notification request state dispatcher
 * ------------------------------
 */
export type NotificationRequestStateType = AppState["notificationRequestState"];

export const setFirstApplicationNotificationRequested = createAction<
  NotificationRequestStateType["firstApplication"]
>("setFirstApplicationNotificationRequested");

export const useSetFirstApplicationNotificationRequested =
  getActionDispatcherWithPayload(setFirstApplicationNotificationRequested);

export const setInMatchingNotificationRequested = createAction<
  NotificationRequestStateType["inMatching"]
>("setInMatchingNotificationRequested");

export const setAvailabilityShown = createAction<AppState["availabilityShown"]>(
  "toggleAvailabilityShown",
);

export const useSetInMatchingNotificationRequested =
  getActionDispatcherWithPayload(setInMatchingNotificationRequested);

export const useSetReduxAvailabilityShown =
  getActionDispatcherWithPayload(setAvailabilityShown);
/**
 * -------------------------------
 */

// these values will be merged
// with the base app state initial values
// inside `createSlice` function below
export const initialState = {
  notificationRequestState: {
    firstApplication: false,
    inMatching: false,
  },
} as AppState;

const { reducer, actions } = createSlice<AppState>({
  initialState,
  reducers(builder) {
    const initializeNotificationRequestState = (state: AppState) => {
      if (!state.notificationRequestState) {
        // if the state is not present, we'll initialize it
        state.notificationRequestState = {
          firstApplication: false,
          inMatching: false,
        };
      }
    };

    const initializeAvailabilityShownState = (state: AppState) => {
      if (!state.availabilityShown) {
        state.availabilityShown = undefined;
      }
    };

    builder.addCase(
      setFirstApplicationNotificationRequested,
      (state, action) => {
        initializeNotificationRequestState(state);
        state.notificationRequestState.firstApplication = action.payload;
      },
    );

    builder.addCase(setInMatchingNotificationRequested, (state, action) => {
      initializeNotificationRequestState(state);
      state.notificationRequestState.inMatching = action.payload;
    });

    builder.addCase(setAvailabilityShown, (state, action) => {
      initializeAvailabilityShownState(state);
      state.availabilityShown = action.payload;
    });
  },
});

export const appReducer = reducer;
/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { incrementBy } = useAppSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(incrementBy(3));
 *   };
 * }
 */
export const useAppActions = () => {
  // append any `createAction` result here
  return {
    ...actions,
    //incrementBy
  };
};
