import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

export default function getActionDispatcherWithPayload<T>(
  actionCreator: ActionCreatorWithPayload<T>,
) {
  return () => {
    const dispatch = useDispatch();
    return useCallback(
      (payload: T) => {
        dispatch(actionCreator(payload));
      },
      [dispatch],
    );
  };
}
