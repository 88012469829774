import { createSlice } from "@marta/core/features/cdn/slice";

import { CdnState } from "./cdn-types";

// these values will be merged
// with the base app state initial values
// inside `createSlice` function below
export const initialState = {} as CdnState;

const { reducer } = createSlice<CdnState>({
  initialState,
});

export const cdnReducer = reducer;
