import { isEqual } from "lodash";

import { AppLocale, AuthRole, EntityType } from "@marta/common-utils";

import { LocaleMap } from "./types";

const simpleCached = Object.values(AppLocale).reduce((acc, locale) => {
  acc[locale] = new Map<string, string>();
  return acc;
}, {} as Record<string, Map<string, string>>);

const cachedArgs = Object.values(AppLocale).reduce((acc, locale) => {
  acc[locale] = new Map<string, any>();
  return acc;
}, {} as Record<string, Map<string, any>>);

const sanitize = (s = "") => {
  return s?.replace(/_/g, "").toLowerCase();
};

const replaceBraces = (s = "", args: Record<string, any>) => {
  return s?.replace(/{(.+?)}/g, (_, v) => {
    return args[v] != null ? args[v] : v;
  });
};

export const getQuestion =
  (locales: LocaleMap, role: AuthRole) =>
  (entityType: EntityType | undefined, field: string) => {
    const _field = sanitize(field);
    const _entityType = sanitize(entityType as string);

    const localeResult =
      locales[`${_field}.${_entityType}.question.${role || role}`] ||
      locales[`${_field}.question.${role || role}`] ||
      locales[`${_field}.${_entityType}.question${role || role}`] ||
      locales[`${_field}.${_entityType}.question`];

    return localeResult;
  };

export const getCached =
  (locale: AppLocale, locales: LocaleMap) =>
  (key: string, args?: Record<string, any>) => {
    const cleanKey = sanitize(key);
    const cachedValue = simpleCached[locale].get(cleanKey);

    if (cachedValue != null) {
      if (args) {
        if (isEqual(args, cachedArgs[locale].get(cleanKey))) {
          return cachedValue;
        }
      } else {
        return cachedValue;
      }
    }

    const localeValue = locales[cleanKey];

    if (!localeValue) {
      return undefined;
    }

    const newValue = args ? replaceBraces(localeValue, args) : localeValue;

    simpleCached[locale].set(cleanKey, newValue);
    cachedArgs[locale].set(cleanKey, args);

    return newValue;
  };

// default translation method
export const get =
  (locale: AppLocale, locales: LocaleMap) =>
  (key: string, args?: Record<string, any>) => {
    return getCached(locale, locales)(key, args) || `%${key}%`;
  };

export const translate =
  (locale: AppLocale, locales: { [key: string]: string }) =>
  (indicator: string) =>
  (context: string, key: string) =>
    get(locale, locales)(`${context}${indicator}${key}`);

export const getWithEntity =
  (locales: LocaleMap) => (entity: string, token: string) => {
    const sanitized = sanitize(`${token}.${entity}`);
    return (
      locales[sanitized] ||
      locales[sanitize(entity)] ||
      `%${sanitize(`${token}.${entity}`)}%`
    );
  };

export const clearTranslatedLocaleCache = (locale: AppLocale) => {
  simpleCached[locale].clear();
};
