import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

import { useCgSession } from "@/auth";

import { initialState } from "./job-reducer";
import { JobState } from "./job-types";

const selectSlice = <T extends { job: JobState }>(state: T) =>
  state.job || initialState;

const selectFiltersByUser = createSelector(
  [
    (state: { job: JobState }) => state.job.filtersByUser,
    (state, userId) => userId,
  ],
  (filtersByUser, userId) => filtersByUser[userId] || [],
);

const selectFilters = createSelector(
  [selectSlice],
  (state) => state.filtersByUser,
);

export const useFilters = () => {
  const { userId } = useCgSession();
  return useSelector((state) => selectFiltersByUser(state, userId));
};

export const useFiltersByUser = () => useSelector(selectFilters);
